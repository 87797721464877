import React, { useEffect, useMemo, useState } from "react";
import { Button, useDataProvider, useGetList, } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Card, MenuItem, Select } from "@mui/material";
import { omit } from "lodash/object";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: "unset!important"
        },
        '& .MuiOutlinedInput-root': {
            width: "100%",
        },
    },
}));

const RecipeBookDependencesDataContainer = (props) => {
    const { data: recipeBookAmountDishesInDiets, loading: loading1 } = useGetList(
        'recipe_book_amount_dishes_in_diets',
        { page: 1, perPage: 1000 },
    );
    const { data: recipeBookCalorifics, loading: loading2 } = useGetList(
        'recipe_book_calorifics',
        { page: 1, perPage: 1000 },
    );
    const { data: recipeBookCalorificsDishes, loading: loading3 } = useGetList(
        'recipe_book_calorific_dishes',
        { page: 1, perPage: 1000 },
    );
    const { data: dishCategories, loading: loading4 } = useGetList(
        'dish_categories',
        { page: 1, perPage: 1000 },
    );
    const { data: recipeBookGenders, loading: loading5 } = useGetList(
        'recipe_book_genders',
        { page: 1, perPage: 1000 },
    );

    if (loading1 || loading2 || loading3 || loading4 || loading5) {
        return <CircularProgress />
    }

    return <RecipeBookDependences recipeBookAmountDishesInDiets={recipeBookAmountDishesInDiets} recipeBookCalorifics={recipeBookCalorifics} recipeBookCalorificsDishes={recipeBookCalorificsDishes} dishCategories={dishCategories} recipeBookGenders={recipeBookGenders} />
}

const RecipeBookDependences = ({
    recipeBookAmountDishesInDiets,
    recipeBookCalorifics,
    recipeBookCalorificsDishes,
    dishCategories,
    recipeBookGenders
}) => {
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [chosenTabData, setChosenTabData] = useState(null);
    const [tabFilteredDependencies, setTabFilteredDependencies] = useState([]);
    const [tempGenders, setTempGenders] = useState({});
    const [tempCalorificDishes, setTempCalorificDishes] = useState({});
    const [tabLoading, setTabLoading] = useState(false);

    const [dependencesTable, setDependencesTable] = useState([])

    const { data: recipeBookDependences, refetch: recipeBookDependencesRefetch } = useGetList(
        'recipe_book_dependences',
        { page: 1, perPage: 10000 },
    );

    const createDependenciesTable = () => {
        if (chosenTabData) {
            const table = Object.keys(recipeBookCalorifics).map(calorific => {
                const calorificFilteredDependency = tabFilteredDependencies.filter(dep => dep.calorific === calorific)
                const calorificRow = {
                    id: calorific,
                    genders: calorificFilteredDependency.length ? calorificFilteredDependency[0].genders : tempGenders[calorific] ? tempGenders[calorific] : [],
                }

                const calorificTempValues = tempCalorificDishes[calorific];
                chosenTabData.categories.forEach(category => {
                    const calorificValue = calorificFilteredDependency.find(dep => dep.dishCategory === category)
                    calorificRow[category] = calorificValue?.calorificDish ? calorificValue?.calorificDish : (calorificTempValues && calorificTempValues[category] ? calorificTempValues[category] : '');
                })

                return calorificRow
            })

            setDependencesTable(table)
        }
    }

    useEffect(() => {
        if (Object.keys(recipeBookDependences).length) {
            setTabFilteredDependencies(Object.values(recipeBookDependences || [])?.filter(({ amountDishesInDiet }) => amountDishesInDiet === chosenTabData?.id))
        }
    }, [chosenTabData, recipeBookDependences])

    useEffect(() => {
        createDependenciesTable()
    }, [tabFilteredDependencies, tempCalorificDishes, tempGenders])

    useEffect(() => {
        setTabLoading(false)
    }, [chosenTabData])

    const getDishCategoryName = (category) => {
        return Object.entries(dishCategories)?.map((e, i) => {
            if (e[0] === category) {
                return <span key={i}>{e[1].title}</span>
            }
        })
    }

    const setChosenTableHandler = (id) => (e) => {
        Object.entries(recipeBookAmountDishesInDiets)?.map(item => {
            if (item[1].id === id) {
                setChosenTabData(item[1])
                setTabLoading(true)
            }
        })
    }

    const genderOptions = useMemo(() => {
        return Object.entries(recipeBookGenders).map(([value, { title }]) => {
            return (
                <MenuItem key={value} value={value}>{title}</MenuItem>
            )
        })
    }, [recipeBookGenders])

    const calorificOptions = useMemo(() => {
        return Object.entries(recipeBookCalorificsDishes).map(([value, { title }]) => {
            return (
                <MenuItem key={value} value={value}>{title}</MenuItem>
            )
        })
    }, [recipeBookCalorificsDishes])

    const onGenderSet = async (calorific, genders) => {
        const calorificFilteredDependencies = tabFilteredDependencies.filter(dep => dep.calorific === calorific)
        if (calorificFilteredDependencies.length) {
            for (const dep of calorificFilteredDependencies) {
                await dataProvider.update('recipe_book_dependences', {
                    id: dep.id,
                    data: {
                        ...dep,
                        genders
                    }
                })
            }
            recipeBookDependencesRefetch();
        } else {
            const pendingValues = tempCalorificDishes[calorific]
            if (pendingValues) {
                for (const pendingValueCategory in pendingValues) {
                    await dataProvider.create('recipe_book_dependences', {
                        data: {
                            amountDishesInDiet: chosenTabData?.id,
                            genders,
                            calorific,
                            dishCategory: pendingValueCategory,
                            calorificDish: pendingValues[pendingValueCategory]
                        }
                    })
                }
                setTempCalorificDishes(omit(tempCalorificDishes, calorific))
                recipeBookDependencesRefetch();
            } else {
                setTempGenders({
                    ...tempGenders,
                    [calorific]: genders
                })
            }
        }
    }

    const onValueSet = async (calorific, category, value) => {
        const dep = tabFilteredDependencies.find(dep => dep.calorific === calorific && dep.dishCategory === category)
        if (dep) {
            await dataProvider.update('recipe_book_dependences', {
                id: dep.id,
                data: {
                    ...dep,
                    calorificDish: value
                }
            }, {
                undoable: false,
                mutationMode: "optimistic"
            })
            recipeBookDependencesRefetch();
        } else {
            const firstCalorific = tabFilteredDependencies.find(dep => dep.calorific === calorific)
            if (firstCalorific) {
                await dataProvider.create('recipe_book_dependences', {
                    data: {
                        ...omit(firstCalorific, ['id', '@id']),
                        dishCategory: category,
                        calorificDish: value
                    }
                })
                recipeBookDependencesRefetch();
            } else {
                if (tempGenders[calorific]) {
                    await dataProvider.create('recipe_book_dependences', {
                        data: {
                            amountDishesInDiet: chosenTabData?.id,
                            genders: tempGenders[calorific],
                            calorific,
                            dishCategory: category,
                            calorificDish: value
                        }
                    })
                    setTempGenders(omit(tempGenders, calorific))
                    recipeBookDependencesRefetch();
                } else {
                    setTempCalorificDishes({
                        ...tempCalorificDishes,
                        [calorific]: {
                            ...tempCalorificDishes[calorific],
                            [category]: value
                        }
                    })
                }
            }
        }
    }

    return <>

        <div style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "20px",
            marginBottom: "20px",
            flexWrap: "wrap",
            gap: "15px"
        }}>
            {Object.entries(recipeBookAmountDishesInDiets)?.map((item) => {
                return (
                    <Card key={item[1].id}>
                        <Button id={item[1].id} label={item[1].title} style={{ fontWeight: "bold" }}
                            onClick={setChosenTableHandler(item[1].id)} />
                    </Card>
                )
            })}
        </div>
        {tabLoading && <CircularProgress />}
        {chosenTabData !== null && !tabLoading &&
            <Card className={classes.root} style={{ padding: "20px" }}>
                <table className={"custom-table"}>
                    <thead>
                        <tr>
                            <th>Kcal w diecie</th>
                            <th>Płeć</th>
                            {chosenTabData?.categories?.map(category => {
                                return <th key={category}>{getDishCategoryName(category)}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(recipeBookCalorifics)?.map((item, index) => {
                            return (
                                <tr key={item[0] + chosenTabData?.id}>
                                    <td>{item[1].title}</td>
                                    <td key={`td${chosenTabData?.id}${item[1].id}`}>
                                        <Select
                                            multiple={true}
                                            id={index + "-" + chosenTabData?.id + "-" + item[1].id}
                                            key={`${chosenTabData?.id}${item[1].id}`}
                                            name={item[1].id}
                                            //                  defaultValue={"/api/recipe_book_genders/50f3e123-0b0e-4f37-b679-63c34bd400cf"}
                                            value={dependencesTable[index]?.genders || []}
                                            onChange={e => {
                                                onGenderSet(e.target.name, e.target.value)
                                            }
                                            }
                                        >{genderOptions}</Select>
                                    </td>
                                    {chosenTabData?.categories?.map(category => {
                                        return <td key={`td${chosenTabData?.id}${category}${item[1].id}`}>
                                            <Select
                                                id={chosenTabData?.id + "-" + category + "-" + item[1].id}
                                                key={`${chosenTabData?.id}${category}${item[1].id}`}
                                                //                    defaultValue={'/api/recipe_book_calorific_dishes/406a4eb4-debe-4ff2-a360-91ac3361ce1a'}
                                                value={dependencesTable[index] ? dependencesTable[index][category] ?? '' : ''}
                                                name={category}
                                                onChange={e => {
                                                    onValueSet(item[1].id, category, e.target.value)
                                                }}>
                                                {calorificOptions}
                                            </Select>
                                        </td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card>}
    </>
}
export default RecipeBookDependencesDataContainer;


