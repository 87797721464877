import React, {cloneElement, useEffect, useState} from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    AutocompleteInput,
    ReferenceArrayField,
    Datagrid,
    EditButton,
    useRecordContext, useGetList, SingleFieldList, ChipField, useDataProvider, useEditController, EditContextProvider,
} from 'react-admin';
import {Button, CircularProgress} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {useHistory} from "react-router";
import {omit, pick} from "lodash/object";

const CustomEdit = props => {
    const [units, setUnits] = useState([])
    const dataProvider = useDataProvider();
    const controllerProps = useEditController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = controllerProps;


    useEffect(async () => {
        if (record?.units) {
            let arr = []
            for (const unit of record.units) {
                const record = await dataProvider.getOne('ingredient_units', {
                    id: unit
                })
                if (record?.data) {
                    arr.push(pick(record.data, ['from', 'ingredient', 'to', 'measure', 'id']));
                }
            }
            setUnits(arr)

        }
    }, [record?.units])
    console.log(record)
    if (!record) return <CircularProgress/>
    const newRecord = {
        ...controllerProps.record,
        units: units?.sort((a, b) => a.from - b.from),
    }
    console.log(newRecord)
    // props.onGetIngredients()
    return <EditContextProvider value={{
        ...controllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </EditContextProvider>
}
const ProductEdit = (props) => {
    const dataProvider = useDataProvider();

    const transform = async data => {

        if (data.units && data.units.length > 0) {
            const mapped = [];
            for (const ifd of data.units) {
                console.log(ifd)
                const body = omit(ifd, 'id');

                if (ifd.id) {
                    await dataProvider.update('ingredient_units', {
                        id: ifd.id,
                        data: {...body}
                    })

                    mapped.push(ifd.id)
                } else {
                    const record = await dataProvider.create('ingredient_units', {
                        data: {...body}
                    })
                    mapped.push(record.data.id);
                }
            }

            data.units = mapped;
        }

        return data;
    };

    return (
        <CustomEdit title='Edytuj potrawę'  {...props} transform={transform}>
            <SimpleForm redirect={'/ingredients'}>
                <TextInput source="title" label="Nazwa"/>
                <NumberInput source='value' label="Wartość produktu"/>
                <NumberInput source='fat' label="Tłuszcze na 100g"/>
                <NumberInput source='carbohydrate' label="Węglowodany na 100g"/>
                <NumberInput source='protein' label="Białko na 100g"/>
                <NumberInput source='calorific' label="Ilość kcal"/>
                <span>Miary kuchenne</span>
                <ArrayInput source="units" label={""}>
                    <SimpleFormIterator>
                        <TextInput source="measure" label={"Jednostka(np. 1 łyżeczka)"} />
                        <NumberInput source="from" label={"Od(gram)"} min={0}/>
                        <NumberInput source="to" label={"Do(gram)"} min={0}/>
                    </SimpleFormIterator>
                </ArrayInput>

                <span>Cena podana w groszach</span>
                <NumberInput source='price' label="Cena produktu na 100g"/>
                <ReferenceArrayInput label="Kategoria zamienników" source="categories"
                                     reference="ingredient_categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
            </SimpleForm>
        </CustomEdit>
    )
}

const Label = props => {
    const record = useRecordContext()
    return <span>{record}</span>;
}


export default ProductEdit;