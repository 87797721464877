import {
    BooleanField,
    Create,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const UserCreate = (props) => {
    return <Create title='Katagorie zakładek' {...props}>
        <SimpleForm redirect={"/tab_categories"}>
            <TextInput source='title' label="Nazwa zakładki"/>
            <NumberInput source='tabOrder' label="Kolejność" />
        </SimpleForm>
    </Create>
}
export  default UserCreate;