import {
    BooleanField,
    Create,
    List,
    NumberInput, ReferenceArrayInput,
    ReferenceInput, SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const RecipeBookDependencesCreate = (props) => {
    return <Create title='Płeć' {...props}>
        <SimpleForm redirect={"/recipe_book_amount_dishes_in_diets"}>
            <TextInput source='title' label="Ilość posiłków w diecie"/>
            <ReferenceArrayInput label="Kategoria potraw" source="categories" reference="dish_categories">
                <SelectArrayInput optionText="title" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
}
export  default RecipeBookDependencesCreate;