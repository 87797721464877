import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { RecordContextProvider, useRecordContext } from 'ra-core';
import { FileField, FileInput, ReferenceField } from 'react-admin';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const useStyles = makeStyles(
	{
		root: { display: 'inline-block' },
	},
	{ name: 'RaFileField' }
);

const FileFieldWithImageUrl = (props) => {
	const {
		className,
		classes: classesOverride,
		emptyText,
		source,
		title,
		src,
		target,
		download,
		ping,
		rel,
		...rest
	} = props;
	const record = useRecordContext(props);
	const sourceValue = get(record, source);
	const classes = useStyles(props);

	const titleValue = get(record, title) || title;

	return (
		<div className={classnames(classes.root, className)}>
			<a
				href={`${process.env.REACT_APP_IMAGE_URL}${sourceValue}`}
				title={titleValue}
				target={target}
				download={download}
				ping={ping}
				rel={rel}
			>
				{titleValue}
			</a>
		</div>
	);
};

const CustomFileField = (props) => {
	const [refresh, setRefresh] = useState(false);
	const [isInitialValue, setIsInitialValue] = useState(false);

	const [data, setData] = useState(props.choices);
	const { input, meta } = useField(props.name);

	const setFile = (file) => {
		setData({ ...data, ['/api/attachments/' + file.id]: file });
		input.onChange('/api/attachments/' + file.id);
	};

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			const formData = new FormData();
			formData.append('file', file);

			setIsInitialValue(true);
			fetch(process.env.REACT_APP_IMAGE_URL + 'api/attachments', {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Access-Control-Allow-Origin': window.location.hostname,
				},
			})
				.then((res) => res.json())
				.then((data) =>
					setFile({
						...data,
						contentUrl: `${process.env.REACT_APP_IMAGE_URL}${data.contentUrl}`,
						file,
					})
				);
		});
	}, []);
	return (
		<>
			{
				<FileInput
					source={props.name}
					label={props.label}
					accept={props.accept}
					options={{ onDrop: onDrop }}
				>
					<>
						{isInitialValue ? (
							<RecordContextProvider
								value={data ? data[input.value] : undefined}
							>
								<FileFieldWithImageUrl source="contentUrl" title="file.name" />
							</RecordContextProvider>
						) : (
							<ReferenceField source={props.name} reference="attachments">
								<FileField source="contentUrl" title="id" />
							</ReferenceField>
						)}
					</>
				</FileInput>
			}
		</>
	);
};

export { CustomFileField };
