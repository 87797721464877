import {BooleanField, Create, List, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const UserCreate = (props) => {
    return <Create title='Nowy użytkownik' {...props}>
        <SimpleForm redirect={'/users'}>
            <TextInput source='name' label="Imię"/>
            <TextInput source='surname' label="Nazwisko" />
            <TextInput source='email' label="Email"/>
            <ReferenceInput label="Grupa dostępu" source="group" reference="groups">
                <SelectInput optionText="title" />
            </ReferenceInput>
            <PasswordCustomInput source={'password'} label={'Ustaw hasło'}/>
        </SimpleForm>
    </Create>
}
export  default UserCreate;