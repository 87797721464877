import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput, DateField
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";


const FaqEdit = (props) => {
    return (
        <Edit title='Edytuj FAQ...'  {...props}>
            <SimpleForm>
                <TextInput multiline source="question" label="Pytanie" />
                {/*<SelectInput label={"Kategoria"} source="category" choices={[*/}
                {/*    { id: 1, name: 'Informacje żywieniowe' },*/}
                {/*    { id: 2, name: 'Informacje treningowe' },*/}
                {/*]} />*/}
                <RichTextInput multiline source="answer" label="Odpowiedź" />
                {/*<DateInput source='editeddAt' label="Data edycji"/>*/}
            </SimpleForm>

        </Edit>
    )
}

export default FaqEdit;