import React from 'react'
import {SelectInput, useGetList, useRecordContext} from "react-admin";
import Select from 'react-select';


function FilteredSelectField({...props}) {
    const {choices} = props;


    return <Select isMulti {...props} options={choices.map(c => ({value: c.id, label: c.title}))}  />
}


export default FilteredSelectField;