import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput, SelectArrayInput, SelectField
} from 'react-admin';


const IngredientCategoryCreate = (props) => {
    return (
        <Create title='Stwórz kategorię potrawy...'  {...props}>
            <SimpleForm redirect={'/dish_categories'}>
                <TextInput source="title" label="Kategoria" />
                <SelectInput source="color" label="Kolor kategorii" choices={[
                    { id: '#ffe49a', name: 'Żółty' },
                    { id: '#f2b087', name: 'Pomarańczowy' },
                    { id: '#6a9346', name: 'Zielony - ciemny' },
                    { id: '#acaba9', name: 'Szary' },
                    { id: '#a7d08d', name: 'Zielony - jasny' },
                    { id: '#bdd6ee', name: 'Niebieski - jasny' },
                    { id: '#ffcccc', name: 'Różowy - jasny' },
                    { id: '#d8bfd8', name: 'Wrzosowy' },
                    { id: '#cccc66', name: 'Zielony 2' },
                ]} />
                {/*<ReferenceArrayInput label="Potrawy" reference="dishes" source="dishes">*/}
                {/*    <SelectArrayInput optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}
            </SimpleForm>

        </Create>
    )
}

export default IngredientCategoryCreate;