import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    ReferenceField,
    SingleFieldList,
    ReferenceArrayField,
    Pagination,
    Filter,
    TextInput,
    ChipField,
    NumberInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label={"Szukaj po nazwie"} source={"title"} alwaysOn/>
    </Filter>
)

 const ProductList = (props) => {
    return <List {...props} exporter={false} filters={<SearchFilter />}>
        <Datagrid>
            <TextField source="title" label="Nazwa produktu" />
            <NumberField source='value' label="Wartość produktu"/>
            <NumberField source='fat' label="Tłuszcze na 100g"/>
            <NumberField source='carbohydrate' label="Węglowodany na 100g"/>
            <NumberField source='protein' label="Białko na 100g"/>
            <NumberField source='calorific' label="Kaloryczność na 100g"/>
            <NumberField source='price' label="Cena produktu na 100g"/>
            <ReferenceArrayField label="Kategoria" reference="ingredient_categories" source="categories">
                <SingleFieldList>
                     <ChipField source="title" label="Kategoria" style={{color:"#f1562f"}}/>
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton basePath='/ingredients' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/ingredients' label="Usuń" />
        </Datagrid>
    </List>
}

export default ProductList;