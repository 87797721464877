import React, {useEffect, useState} from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    TextInput,
    EditButton,
    DeleteButton,
    Filter, SearchInput, ReferenceField
} from 'react-admin';
import {useIntrospection} from "@api-platform/admin";
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";

const postFilters = [
    <TextInput label="Id klienta" source="wordpressId" alwaysOn />,
    <TextInput label="Email klienta" source="email" alwaysOn />,
];

const ClientList = (props) => {
    return <List
        filters={postFilters}
        exporter={false}
        // filters={<UserFilter />}
        {...props}>
        <Datagrid>
            <TextField label={'Id'} source='wordpressId' />
            <TextField source="name" label="Imię" />
            <TextField source='surname' label="Nazwisko"/>
            <EmailField source='email' label="Email"/>
            <EditButton basePath='/clients' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/clients' label="Usuń" />
        </Datagrid>
    </List>
}
export default ClientList;


