import React, {useEffect, useState} from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    Filter, SearchInput, ReferenceField, NumberField, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import {useIntrospection} from "@api-platform/admin";
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";
import {ListActions} from "../Meals/MealList";



const RecipeBookLabelsList = (props) => {
    // const UserFilter = (props) => (
    //     <Filter {...props}>
    //         <SearchInput source="name" resettable alwaysOn/>
    //     </Filter>
    // )


    return <List
        exporter={false}
        // filters={<UserFilter />}
        actions={<ListActions/>}
        {...props}>
        <Datagrid>
            <TextField source='title' label="Nazwa"/>
            <ReferenceArrayField label="Kategorie potraw" reference="dish_categories" source="categories">
                <SingleFieldList>
                    <ChipField source="title" style={{color:"#f1562f"}}/>
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton  label="Edytuj"/>
            <DeleteButton label="Usuń" />
        </Datagrid>
    </List>
}
export default RecipeBookLabelsList;


