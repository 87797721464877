import {
    BooleanField,
    Create,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const GenderCreate = (props) => {
    return <Create title='Płeć' {...props}>
        <SimpleForm redirect={"/recipe_book_genders"}>
            <TextInput source='title' label="Płeć"/>
        </SimpleForm>
    </Create>
}
export  default GenderCreate;