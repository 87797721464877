import { cloneElement, useEffect, useState } from 'react';
import {
	SimpleForm,
	TextInput,
	SelectInput,
	ReferenceArrayInput,
	useGetList,
	AutocompleteArrayInput,
	ReferenceInput,
	useDataProvider,
	useEditController,
	EditContextProvider,
	BooleanInput,
} from 'react-admin';
import { spanStyle } from './DietMakerCreate';
import { CircularProgress } from '@material-ui/core';
import { map } from 'lodash/collection';
import { CustomFileField } from '../Fields/CustomFileField';

const fields = [
	{
		name: 'breakfasts',
		target: 'diet_dish_breakfasts',
	},
	{
		name: 'secondBreakfasts',
		target: 'diet_dish_second_breakfasts',
	},
	{
		name: 'lunches',
		target: 'diet_dish_lunches',
	},
	{
		name: 'dinners',
		target: 'diet_dish_dinners',
	},
	{
		name: 'fitDesserts',
		target: 'diet_dish_fit_desserts',
	},
	{
		name: 'fitFastFoods',
		target: 'diet_dish_fit_fast_foods',
	},
	{
		name: 'partySnacks',
		target: 'diet_dish_party_snacks',
	},
	{
		name: 'snacks',
		target: 'diet_dish_snacks',
	},
];

const mapIdToName = (id) => {
	return fields.find((field) => id.includes(field.target))?.name;
};

const mapNameToTarget = (name) => {
	return fields.find((field) => name.includes(field.name))?.target;
};

const CustomEdit = (props) => {
	const [dishes, setDishes] = useState({
		breakfasts: [],
		secondBreakfasts: [],
		lunches: [],
		dinners: [],
		fitDesserts: [],
		fitFastFoods: [],
		partySnacks: [],
		snacks: [],
	});
	const dataProvider = useDataProvider();
	const controllerProps = useEditController(props);

	const {
		basePath, // deduced from the location, useful for action buttons
		defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
		record, // empty object, unless some values were passed in the location state to prefill the form
		redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
		resource, // the resource name, deduced from the location. e.g. 'posts'
		save, // the create callback, to be passed to the underlying form as submit handler
		saving, // boolean that becomes true when the dataProvider is called to create the record
		version, // integer used by the refresh feature
	} = controllerProps;

	useEffect(async () => {
		const tasks = [];

		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];

			if (record && record[field.name]) {
				tasks.push(
					dataProvider.getMany(field.target, {
						ids: record[field.name],
					})
				);
			}
		}

		Promise.all(tasks).then((dishes) => {
			let byTabCat = {};
			const dishKeys = map(fields, 'name');

			for (const k in dishes) {
				if (dishes[k]?.data.length > 0) {
					for (const dish of dishes[k].data) {
						const key = `${dishKeys[k]}|${dish.tabCategory}`;
						if (!byTabCat[key]) {
							byTabCat[key] = [];
						}

						byTabCat[key].push(dish);
					}
				}
			}

			for (const k in byTabCat) {
				byTabCat[k] = byTabCat[k]
					.sort((a, b) => a.dietDishOrder - b.dietDishOrder)
					.map((a) => a.dish);
			}

			setDishes(byTabCat);
			props.setLoading(false);
		});
	}, [
		record?.lunches,
		record?.snacks,
		record?.fitDesserts,
		record?.fitFastFoods,
		record?.dinners,
		record?.partySnacks,
		record?.breakfasts,
		record?.secondBreakfasts,
	]);

	if (!record) return <CircularProgress />;

	const newRecord = {
		...controllerProps.record,
		...dishes,
		// ingredientsForDishes: ingredients?.sort((a,b) => a.ingredientDishOrder - b.ingredientDishOrder),
	};

	return (
		<EditContextProvider
			value={{
				...controllerProps,
				record: newRecord,
			}}
		>
			{cloneElement(props.children, {
				basePath,
				record: newRecord,
				redirect,
				resource,
				save,
				saving,
				version,
			})}
		</EditContextProvider>
	);
};

const DietMakerEdit = (props) => {
	const dataProvider = useDataProvider();
	const [loading, setLoading] = useState(true);
	const transform = async (data) => {
		const newDishesArr = [];
		const tasks = [];

		Object.entries(data).forEach((e) => {
			if (e[0].indexOf('|') >= 0 && Array.isArray(e[1])) {
				e[1].forEach((el, i) => {
					newDishesArr.push([
						e[0].split('|')[0],
						[
							{
								dish: el,
								tabCategory: e[0].split('|')[1],
								dietDishOrder: i + 1,
								diet: null,
							},
						],
					]);
				});
			}
		});

		newDishesArr.forEach((el, index) => {
			return tasks.push(
				dataProvider.create(mapNameToTarget(el[0]), {
					data: el[1][0],
				})
			);
		});

		const dishes = await Promise.all(tasks);

		data = {
			...data,
			breakfasts: [],
			secondBreakfasts: [],
			lunches: [],
			dinners: [],
			fitDesserts: [],
			fitFastFoods: [],
			partySnacks: [],
			snacks: [],
		};

		dishes.forEach((d) => {
			data[mapIdToName(d.data.id)].push(d.data.id);
		});
		return data;
	};

	const { data, error } = useGetList(
		'tab_categories',
		{ page: 1, perPage: 500 },
		{ field: 'id', order: 'ASC' },
		{}
	);

	const [tabCategory, setTabCategory] = useState(false);

	return (
		<CustomEdit
			setLoading={setLoading}
			loading={loading}
			title="Edytuj dietę..."
			{...props}
			transform={transform}
		>
			<SimpleForm redirect={'/diets'}>
				<TextInput source="dietName" label={'Kod e-booka'} />
				<TextInput source="dietShowName" label={'Nazwa e-booka'} />
				<div
					style={{
						border: '2px dashed rgb(238, 238, 238',
						marginBottom: '20px',
						padding: '5px',
						borderRadius: '2px',
					}}
				>
					<CustomFileField
						name={'dietPDF'}
						accept={'application/pdf'}
						label={'Dodaj plik PDF'}
					/>
				</div>
				<BooleanInput
					source="isDigitalVersion"
					label={'Produkt z wersją cyfrową'}
					// defaultValue={true}
				/>
				<ReferenceInput
					label="Kategoria zakładek"
					source={`tabCategory|wat`}
					reference="tab_categories"
					onChange={(e) =>
						e.target.value !== undefined
							? setTabCategory(e.target.value)
							: setTabCategory(false)
					}
				>
					<SelectInput optionText="title" />
				</ReferenceInput>
				{Object.values(data).map((e) => {
					return (
						<>
							{tabCategory == e.id && (
								<div>
									<span style={spanStyle}>I ŚNIADANIE</span>

									<ReferenceArrayInput
										source={`breakfasts|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>II ŚNIADANIE</span>

									<ReferenceArrayInput
										source={`secondBreakfasts|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>OBIAD</span>
									<ReferenceArrayInput
										source={`lunches|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>KOLACJA</span>
									<ReferenceArrayInput
										source={`dinners|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>FIT DESERY</span>
									<ReferenceArrayInput
										source={`fitDesserts|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>FIT FAST FOOD</span>
									<ReferenceArrayInput
										source={`fitFastFoods|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>
									<span style={spanStyle}>IMPREZOWE PRZEKĄSKI</span>
									<ReferenceArrayInput
										source={`partySnacks|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>

									<span style={spanStyle}>PRZEKĄSKI</span>

									<ReferenceArrayInput
										source={`snacks|${e.id}`}
										reference="dishes"
										label="Potrawa"
										filterToQuery={(searchText) =>
											searchText
												? {
														dishCode: searchText,
														title: searchText,
												  }
												: {}
										}
										enableGetChoices={tabCategory === e.id}
									>
										<AutocompleteArrayInput
											optionText={(choice) =>
												choice ? `${choice.dishCode} (${choice.title})` : ''
											}
										/>
									</ReferenceArrayInput>
								</div>
							)}
						</>
					);
				})}
			</SimpleForm>
		</CustomEdit>
	);
};

export default DietMakerEdit;
