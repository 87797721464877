import {
    BooleanField,
    Edit,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const RecipeBookCalorificsEdit = (props) => {
    return <Edit title='Płeć' {...props}>
        <SimpleForm redirect={"/recipe_book_calorifics"}>
            <NumberInput source='title' label="Ilość kalorii w diecie"/>
        </SimpleForm>
    </Edit>
}
export  default RecipeBookCalorificsEdit;