import React, {Component, useState} from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    ReferenceArrayField,
    ReferenceField,
    useDataProvider,
    useGetList,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    Edit,
    useInput, Button, Toolbar, TextField, SaveButton,
} from 'react-admin';
import CustomArrayImageField, {CustomImageField} from "../Fields/CustomArrayImageField";
import Select from 'react-select';
import dataProvider from "../../providers/dataProvider";
import FilteredSelectField from "../Fields/CustomSelectField";
import {useTheme} from "@material-ui/core";
import {Save} from "@material-ui/icons";
import {useFormState} from "react-final-form";
import {useHistory} from "react-router";
import {spanStyle} from "../DietMaker/DietMakerCreate";
import RichTextInput from "ra-input-rich-text";
import ReferenceIdField from "../Fields/ReferenceIdField";


export const GoBackToolbar = (props) => {
    const history = useHistory();
    const { values }   = useFormState();
    const dataProvider = useDataProvider()
    const onSuccess = () => {
        history.goBack();
    };

    const tasks = [];
    const save = () => {
        onSuccess();
//        Promise.all(tasks).then( () => {
//            dataProvider.update('ingredient_dishes', {
//                data: {
//                    ...values,
//                }
//            }).then(() => onSuccess())
//        })

    }
    return (
        <Toolbar {...props}>
            <SaveButton onSuccess={onSuccess} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};


const MealEdit = (props) => {
    const { data, ids, total, loading, loaded, error, refetch } = useGetList(
        'ingredients',
        {page:1, perPage:1000}
    );

    const choices = (el) => {

       return Object.values(el).map( (e) => ({
           id: e.id,
           name: e.title
       }))
    }

    const history = useHistory();
    const dishId = history?.location?.state?.dishId


    return (
        <Edit title='Stwórz posiłek'  {...props} >
            <SimpleForm undoable={false} redirect={history.goBack}>
                {/*<ReferenceIdField source={dishId} name={"dish"}/>*/}

                <AutocompleteInput label={"Produkty"} source="ingredient" choices={choices(data)}/>
                <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>
                <NumberInput source={"grams"} label={"Ilość gram"}/>

                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <FilteredSelectField  optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

            </SimpleForm>

        </Edit>
    )
}

export default MealEdit;
