import {
    BooleanField,
    Create,
    List,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Button,
    DateInput,
    PasswordInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const ClientCreate = (props) => {
    return <Create title='Nowy klient' {...props}>
          <SimpleForm undoable={false} redirect={'/clients'}>
              <TextInput source='name' label="Imię"/>
              <TextInput source='surname' label="Nazwisko" />
              <TextInput source='email' label="Email"/>
              <PasswordCustomInput source={'password'} label={'Hasło'} />
            </SimpleForm>
    </Create>
}
export  default ClientCreate;