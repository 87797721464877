import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField, ReferenceField, SingleFieldList, ReferenceArrayField, ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

 const IngredientCategoryList = (props) => {
    return <List {...props} exporter={false}>
        <Datagrid>
            <TextField source="title" label="Kategoria" />
            <SelectField source="color" label="Kolor kategorii" choices={[
                { id: '#ffe49a', name: 'Żółty' },
                { id: '#f2b087', name: 'Pomarańczowy' },
                { id: '#6a9346', name: 'Zielony - ciemny' },
                { id: '#acaba9', name: 'Szary' },
                { id: '#a7d08d', name: 'Zielony - jasny' },
                { id: '#bdd6ee', name: 'Niebieski - jasny' },
                { id: '#ffcccc', name: 'Różowy - jasny' },
                { id: '#d8bfd8', name: 'Wrzosowy' },
                { id: '#cccc66', name: 'Zielony 2' },
            ]} />
            <NumberField source='dishOrder' label="Kolejność"/>
            <NumberField source='countDishes' label="Ilość potraw"/>

            <EditButton basePath='/dish_categories' label="Edytuj"/>
            <DeleteButtonWithConfirmation basePath='/dish_categories' label="Usuń" />
        </Datagrid>
    </List>
}

export default IngredientCategoryList;
