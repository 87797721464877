import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput, ReferenceInput, ReferenceArrayField,ReferenceArrayInput, SingleFieldList, ChipField,SelectArrayInput
} from 'react-admin';


const IngredientCategoryEdit = (props) => {
    return (
        <Edit title='Edytuj kategorię'  {...props}>
            <SimpleForm>
                <TextInput source="title" label="Kategoria" />
                <SelectInput source="color" label="Kolor kategorii" choices={[
                    { id: '#ffe49a', name: 'Żółty' },
                    { id: '#f2b087', name: 'Pomarańczowy' },
                    { id: '#6a9346', name: 'Zielony - ciemny' },
                    { id: '#acaba9', name: 'Szary' },
                    { id: '#a7d08d', name: 'Zielony - jasny' },
                    { id: '#bdd6ee', name: 'Niebieski - jasny' },
                    { id: '#ffcccc', name: 'Różowy - jasny' },
                    { id: '#d8bfd8', name: 'Wrzosowy' },
                    { id: '#cccc66', name: 'Zielony 2' },
                ]} />
                <NumberInput source={'dishOrder'} label={"Kolejność"} required={true} min={1} />
            </SimpleForm>

        </Edit>
    )
}

export default IngredientCategoryEdit;
