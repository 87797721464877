import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayInput, SimpleFormIterator, AutocompleteInput, useDataProvider
} from 'react-admin';
import {GoBackToolbar} from "../Meals/MealCreate";
import {omit} from "lodash/object";



const ProductCreate = (props) => {
    const dataProvider = useDataProvider();
    const transform = async data => {

        if (data.units && data.units.length > 0) {
            const mapped = [];
            for (const ifd of data.units) {
                console.log(ifd)
                const body = omit(ifd, 'id');

                if (ifd.id) {
                    await dataProvider.update('ingredient_units', {
                        id: ifd.id,
                        data: {...body}
                    })

                    mapped.push(ifd.id)
                } else {
                    const record = await dataProvider.create('ingredient_units', {
                        data: {...body}
                    })
                    mapped.push(record.data.id);
                }
            }

            data.units = mapped;
        }

        return data;
    };

    return (
        <Create  title='Stwórz produkt...'  {...props} transform={transform}>
            <SimpleForm  redirect={'/ingredients'}>
                <TextInput source="title" label="Nazwa" />
                <NumberInput source='value' label="Wartość produktu"/>
                <NumberInput source='fat' label="Tłuszcze na 100g"/>
                <NumberInput source='carbohydrate' label="Węglowodany na 100g"/>
                <NumberInput source='protein' label="Białko na 100g"/>
                <span>Miary kuchenne</span>
                <ArrayInput source="units" label={""}>
                    <SimpleFormIterator>
                        <TextInput source="measure" label={"Jednostka (np. 1 łyżeczka)"}/>
                        <NumberInput source="from" label={"Od (gram)"}/>
                        <NumberInput source="to" label={"Do (gram)"}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput source='price' label="Cena produktu na 100g"/>
                <span>Cena podana w groszach</span>
                <NumberInput source='calorific' label="Ilość kcal"/>
                <ReferenceArrayInput label="Kategoria zamienników" source="categories" reference="ingredient_categories">
                    <SelectArrayInput optionText="title" />
                </ReferenceArrayInput>
                {/*<ArrayInput source="substitutes" label={""}>*/}
                {/*    <SimpleFormIterator>*/}
                {/*        <ReferenceArrayInput reference="ingredients" label="Zamiennik">*/}
                {/*            <AutocompleteInput optionText="title" />*/}
                {/*        </ReferenceArrayInput>*/}
                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}
            </SimpleForm>

        </Create>
    )
}

export default ProductCreate;