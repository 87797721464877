import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	EditButton,
	DeleteButton,
	EmailField,
	SelectField,
	FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from '../Buttons/DeleteButtonWithConfirmation';

const useStyles = makeStyles({
	button: {
		fontWeight: 'bold',
		fontSize: '45px',
		// This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
		'& svg': { color: 'orange' },
	},
});

const MyEditButton = (props) => {
	const classes = useStyles();
	// return <EditButton className={classes.button} {...props} />;
	return <EditButton {...props} />;
};

const MealList = (props) => {
	return (
		<List
			{...props}
			exporter={false}
			sort={{ field: 'question', order: 'DESC' }}
		>
			<Datagrid>
				<TextField multiline source="question" label="Pytanie" />
				{/*<SelectField label={"Kategoria"} source="category" choices={[*/}
				{/*    { id: 1, name: 'Informacje żywieniowe' },*/}
				{/*    { id: 2, name: 'Informacje treningowe' },*/}
				{/*]} />*/}
				<DateField source="updatedAt" label="Data edycji" />
				<EditButton basePath="/faqs" label="Edytuj" />
				<DeleteButtonWithConfirmation basePath="/faqs" label="Usuń" />
			</Datagrid>
		</List>
	);
};

export default MealList;
