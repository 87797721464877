import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	NumberField,
	EditButton,
	DeleteButton,
	EmailField,
	SelectField,
	RichTextField,
	ReferenceField,
	ReferenceManyField,
	SingleFieldList,
	ChipField,
	ReferenceArrayField,
	TextInput,
	Filter,
	CloneButton,
	BooleanField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';
import DeleteButtonWithConfirmation from '../Buttons/DeleteButtonWithConfirmation';

const useStyles = makeStyles({
	button: {
		fontWeight: 'bold',
		fontSize: '45px',
		// This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
		'& svg': { color: 'orange' },
	},
});

const MyEditButton = (props) => {
	const classes = useStyles();
	// return <EditButton className={classes.button} {...props} />;
	return <EditButton {...props} />;
};

const SearchFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Szukaj po kodzie" source="dietName" alwaysOn />
	</Filter>
);

const DietMaker = (props) => {
	return (
		<List {...props} filters={<SearchFilter />} exporter={false}>
			<Datagrid>
				<TextField source="dietName" label={'Kod e-booka'} />
				<TextField source="dietShowName" label={'Nazwa e-booka'} />
				<ReferenceField
					reference={'diet_templates'}
					label={'Szablon'}
					source={'dietTemplate'}
				>
					<TextField source="dietName" label={'Szablon'} />
				</ReferenceField>
				<BooleanField
					looseValue={true}
					source={'isSynced'}
					valueLabelTrue={'Zintegrowano'}
					valueLabelFalse={'W trakcie integracji'}
				/>
				<EditButton basePath="/diets" />
				<CloneButton basePath="/diets" />
				<DeleteButtonWithConfirmation basePath="/diets" />
			</Datagrid>
		</List>
	);
};

export default DietMaker;
