import {Create, List,BooleanInput, SimpleForm, NumberInput, TextField, TextInput, SelectInput} from "react-admin";
import React from "react";


const UserCreate = (props) => {
    return <Create title='Nowa grupa użytkowników' {...props}>
        <SimpleForm redirect={'/groups'}>
            <TextInput source={'title'} label='Nazwa grupy'/>
            <BooleanInput label="Użytkownicy" source="isUsersAccess" />
            <BooleanInput label="Grupy użytkowników" source="isGroupsAccess" />
            {/*<BooleanInput label="Klienci" source="isClientsAccess" />*/}
            <BooleanInput label="Dania" source="isDishesAccess" />
            <BooleanInput label="FAQ" source="isFAQAccess" />
        </SimpleForm>
    </Create>
}
export  default UserCreate;