import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    SingleFieldList,
    ReferenceArrayField,
    ReferenceField,
    ChipField,
    TextInput,
    ReferenceInput,
    SelectInput,
    Filter, SearchInput, CloneButton, CreateButton, TopToolbar, useResourceContext, ReferenceManyField
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import DeleteButtonWithConfirmation from "../Buttons/DeleteButtonWithConfirmation";
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import {Add, FileCopy} from "@material-ui/icons";


const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange'}
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};


const SearchFilter = props => (
    <Filter {...props}>
        <TextInput label="Szukaj po kodzie" source="dishCode" alwaysOn/>
        <TextInput label="Szukaj po nazwie" source="title" alwaysOn/>
    </Filter>
)

export const ListActions = (props) => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
);

const CreateRelatedCommentButton = ({record}) => {
    return <Button
        style={{color: "#f1562f", fontSize: "13px"}}
        component={Link}
        to={{
            pathname: '/recipe_book_dishes/create',
            state: {record},
        }}
    >
        <FileCopy/>

    </Button>
}


const RecipeBookList = (props) => {

    return <List {...props} filters={<SearchFilter/>} actions={<ListActions/>}>
        <>
            <Datagrid>
                <TextField source="title" label="Nazwa potrawy"/>
                <TextField source="dishCode" label="Kod potrawy" style={{width: "600px"}}/>
                <NumberField source='calculatedDishFats' label="Tłuszcze"/>
                <NumberField source='calculatedDishCarbohydrates' label="Węglowodany"/>
                <NumberField source='calculatedDishProteins' label="Białko"/>
                <NumberField source='calculatedDishCalorific' label="Ilość kalorii"/>

                <ReferenceField label="Ilość kcal w posiłku" reference="recipe_book_calorific_dishes"
                                source="calorificDish">
                    <ChipField source="title" style={{color: "#f1562f"}}/>
                </ReferenceField>
                <ReferenceArrayField label="Etykiety" reference="recipe_book_sub_categories" source="subCategories">
                    <SingleFieldList>
                        <ChipField source="title" style={{color: "#f1562f"}}/>
                    </SingleFieldList>
                </ReferenceArrayField>

                <EditButton basePath='/recipe_book_dishes' label=""/>
                <CreateRelatedCommentButton/>
                {/*<CloneButton/>*/}
                <DeleteButtonWithConfirmation basePath='/recipe_book_dishes' label=""/>
            </Datagrid>
        </>
    </List>
}

export default RecipeBookList;
