import { cloneElement, useEffect, useState } from 'react';
import {
	AutocompleteArrayInput,
	Button,
	CreateContextProvider,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	useCreateController,
	useDataProvider,
	useGetList,
	useNotify,
	CheckboxGroupInput,
	BooleanInput,
} from 'react-admin';
import { useHistory } from 'react-router';
import { useFormState } from 'react-final-form';
import { Save } from '@material-ui/icons';
import {
	CircularProgress,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { omitBy } from 'lodash/object';
import { CustomFileField } from '../Fields/CustomFileField';
import { map } from 'lodash/collection';

export const spanStyle = {
	fontSize: '18px',
	fontWeight: 'bold',
};

const mapIdToName = (id) => {
	return fields.find((field) => id.includes(field.target))?.name;
};

const mapNameToTarget = (name) => {
	return fields.find((field) => name.includes(field.name))?.target;
};

const fields = [
	{
		name: 'breakfasts',
		target: 'diet_dish_breakfasts',
	},
	{
		name: 'secondBreakfasts',
		target: 'diet_dish_second_breakfasts',
	},
	{
		name: 'lunches',
		target: 'diet_dish_lunches',
	},
	{
		name: 'dinners',
		target: 'diet_dish_dinners',
	},
	{
		name: 'fitDesserts',
		target: 'diet_dish_fit_desserts',
	},
	{
		name: 'fitFastFoods',
		target: 'diet_dish_fit_fast_foods',
	},
	{
		name: 'partySnacks',
		target: 'diet_dish_party_snacks',
	},
	{
		name: 'snacks',
		target: 'diet_dish_snacks',
	},
];

const GoBackToolbar = (props) => {
	const history = useHistory();
	const { values } = useFormState();
	const dataProvider = useDataProvider();
	const notify = useNotify();
	const onSuccess = () => {
		props.setLoading(false);
		history.goBack();
	};
	const tasks = [];
	const newDishesArr = [];

	const save = () => {
		Object.entries(values).forEach((e) => {
			if (e[0].indexOf('|') >= 0 && Array.isArray(e[1])) {
				e[1].forEach((el, i) => {
					newDishesArr.push([
						e[0].split('|')[0],
						[
							{
								diet: null,
								dish: el,
								tabCategory: e[0].split('|')[1],
								dietDishOrder: i + 1,
							},
						],
					]);
				});
			}
		});

		delete values['orginId'];
		delete values['@id'];
		delete values['wordpressId'];

		newDishesArr.forEach((el, index) => {
			return tasks.push(
				dataProvider.create(mapNameToTarget(el[0]), {
					data: el[1][0],
				})
			);
		});

		const dishes = {};

		Promise.all(tasks).then((ingr) => {
			try {
				ingr.forEach((i) => {
					if (dishes[mapIdToName(i.data.id)]) {
						return dishes[mapIdToName(i.data.id)].push(i.data.id);
					} else {
						return (dishes[mapIdToName(i.data.id)] = [i.data.id]);
					}
				});

				dataProvider
					.create('diets', {
						data: {
							...omitBy(values, (val, key) => {
								return key.indexOf('|') >= 0;
							}),
							...dishes,
						},
					})
					.then((r) => {
						onSuccess();
					})
					.catch((err) => {
						notify('Wystąpił błąd', 'warning');
					});
			} catch (e) {
				console.error(e);
				notify('Wystąpił błąd', 'warning');
			}
		});
	};
	return (
		<Toolbar {...props}>
			<Button
				disabled={props.loading}
				onClick={save}
				children={<Save />}
				label="Zapisz"
				variant={'contained'}
			/>
			<Button onClick={onSuccess} label={'Wstecz'} />
		</Toolbar>
	);
};

const CustomCreate = (props) => {
	const [dishes, setDishes] = useState({
		breakfasts: [],
		secondBreakfasts: [],
		lunches: [],
		dinners: [],
		fitDesserts: [],
		fitFastFoods: [],
		partySnacks: [],
		snacks: [],
	});
	const [isDishesLoaded, setIsDishesLoaded] = useState(false);
	const dataProvider = useDataProvider();
	const controllerProps = useCreateController(props);
	const {
		basePath, // deduced from the location, useful for action buttons
		defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
		record, // empty object, unless some values were passed in the location state to prefill the form
		redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
		resource, // the resource name, deduced from the location. e.g. 'posts'
		save, // the create callback, to be passed to the underlying form as submit handler
		saving, // boolean that becomes true when the dataProvider is called to create the record
		version, // integer used by the refresh feature
		...rest
	} = controllerProps;

	useEffect(async () => {
		const d = dishes;
		const tasks = [];

		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];

			if (record && record[field.name]) {
				// const records = await

				tasks.push(
					dataProvider.getMany(field.target, {
						ids: record[field.name],
					})
				);

				//d[field.name] = records.data.sort((a,b) => a.dietDishOrder - b.dietDishOrder).map(a => a.dish)
			}
		}

		Promise.all(tasks).then((dishes) => {
			let byTabCat = {};
			const dishKeys = map(fields, 'name');

			for (const k in dishes) {
				if (dishes[k]?.data.length > 0) {
					for (const dish of dishes[k].data) {
						const key = `${dishKeys[k]}|${dish.tabCategory}`;
						if (!byTabCat[key]) {
							byTabCat[key] = [];
						}

						byTabCat[key].push(dish);
					}
				}
			}

			for (const k in byTabCat) {
				byTabCat[k] = byTabCat[k]
					.sort((a, b) => a.dietDishOrder - b.dietDishOrder)
					.map((a) => a.dish);
			}

			setDishes(byTabCat);
			setIsDishesLoaded(true);
		});
	}, []);

	if (!record || !isDishesLoaded) return <CircularProgress />;

	const newRecord = {
		...controllerProps.record,
		...dishes,
		// ingredientsForDishes: ingredients?.sort((a,b) => a.ingredientDishOrder - b.ingredientDishOrder),
	};

	return (
		<CreateContextProvider
			value={{
				...controllerProps,
				record: newRecord,
			}}
		>
			{cloneElement(props.children, {
				basePath,
				record: newRecord,
				redirect,
				resource,
				save,
				saving,
				version,
			})}
		</CreateContextProvider>
	);
};

const DietMakerCreate = (props) => {
	const { data, error } = useGetList(
		'tab_categories',
		{ page: 1, perPage: 500 },
		{ field: 'id', order: 'ASC' },
		{}
	);

	const [loading, setLoading] = useState(false);

	const [tabCategory, setTabCategory] = useState(false);

	return (
		<CustomCreate
			setLoading={setLoading}
			loading={loading}
			title="Stwórz dietę"
			{...props}
		>
			{loading ? (
				<CircularProgress
					style={{ position: 'absolute', top: '50%', left: '50%' }}
				/>
			) : (
				<SimpleForm
					toolbar={<GoBackToolbar setLoading={setLoading} loading={loading} />}
					redirect={'/diets'}
				>
					<TextInput source="dietName" label={'Kod e-booka'} />
					<TextInput source="dietShowName" label={'Nazwa e-booka'} />
					<div
						style={{
							border: '2px dashed rgb(238, 238, 238',
							marginBottom: '20px',
							padding: '5px',
							borderRadius: '2px',
						}}
					>
						<CustomFileField
							name={'dietPDF'}
							accept={'application/pdf'}
							label={'Dodaj plik PDF'}
						/>
					</div>
					<BooleanInput
						source="isDigitalVersion"
						label={'Produkt z wersją cyfrową'}
						defaultValue={true}
					/>
					<CheckboxGroupInput
						source="isDigitalVersion"
						name="isDigitalVersion"
					/>

					<ReferenceInput
						label="Kategoria zakładek"
						source={`tabCategory|wat`}
						reference="tab_categories"
						onChange={(e) =>
							e.target.value !== undefined
								? setTabCategory(e.target.value)
								: setTabCategory(false)
						}
					>
						<SelectInput optionText="title" />
					</ReferenceInput>
					{Object.values(data).map((e) => {
						return (
							<>
								{tabCategory === e.id && (
									<div>
										<span style={spanStyle}>I ŚNIADANIE</span>

										<ReferenceArrayInput
											source={`breakfasts|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>II ŚNIADANIE</span>

										<ReferenceArrayInput
											source={`secondBreakfasts|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>OBIAD</span>
										<ReferenceArrayInput
											source={`lunches|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>KOLACJA</span>
										<ReferenceArrayInput
											source={`dinners|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>FIT DESERY</span>
										<ReferenceArrayInput
											source={`fitDesserts|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>FIT FAST FOOD</span>
										<ReferenceArrayInput
											source={`fitFastFoods|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>
										<span style={spanStyle}>IMPREZOWE PRZEKĄSKI</span>
										<ReferenceArrayInput
											source={`partySnacks|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>

										<span style={spanStyle}>PRZEKĄSKI</span>

										<ReferenceArrayInput
											source={`snacks|${e.id}`}
											reference="dishes"
											label="Potrawa"
											filterToQuery={(searchText) =>
												searchText
													? {
															dishCode: searchText,
															title: searchText,
													  }
													: {}
											}
										>
											<AutocompleteArrayInput
												optionText={(choice) =>
													`${choice?.dishCode} (${choice?.title})`
												}
											/>
										</ReferenceArrayInput>
									</div>
								)}
							</>
						);
					})}
				</SimpleForm>
			)}
		</CustomCreate>
	);
};

export default DietMakerCreate;
