import { useState } from 'react';
import {
	Admin,
	AppBar,
	getResources,
	Layout,
	Resource,
	RouteWithoutLayout,
	Sidebar,
	useAuthProvider,
	UserMenu,
} from 'react-admin';
import { NavLink } from 'react-router-dom';
import { Redirect, Route, useLocation } from 'react-router';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { useIntrospection } from '@api-platform/admin';
import { default as baseFetchHydra } from './providers/fetchHydra';
import { default as baseHydraDataProvider } from './providers/dataProvider';
import UserIcon from '@material-ui/icons/Person';
import UsersIcon from '@material-ui/icons/People';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ViewListIcon from '@material-ui/icons/ViewList';
import WcIcon from '@material-ui/icons/Wc';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AppleIcon from '@material-ui/icons/Apple';
import CategoryIcon from '@material-ui/icons/Category';
import LabelIcon from '@material-ui/icons/Label';
import ExposurePlus1Icon from '@material-ui/icons/ExposurePlus1';
import KitchenIcon from '@material-ui/icons/Kitchen';
import AddIcon from '@material-ui/icons/Add';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import UserCreate from './components/User/UserCreate';
import UserList from './components/User/UserList';
import UsersEdit from './components/User/UserEdit';
import UserGroup from './components/UserGroup/UserGroup';
import UserGroupEdit from './components/UserGroup/UserGroupEdit';
import UserGroupCreate from './components/UserGroup/UserGroupCreate';
import PriceList from './components/Prices/PriceList';
import ProductList from './components/Products/ProductList';
import ProductEdit from './components/Products/ProductEdit';
import ProductCreate from './components/Products/ProductCreate';
import MealList from './components/Meals/MealList';
import MealEdit from './components/Meals/MealEdit';
import MealCreate from './components/Meals/MealCreate';
import FaqList from './components/Faq/FaqList';
import FaqCreate from './components/Faq/FaqCreate';
import FaqEdit from './components/Faq/FaqEdit';
import DietMaker from './components/DietMaker/DietMaker';
import DietMakerEdit from './components/DietMaker/DietMakerEdit';
import DietMakerCreate from './components/DietMaker/DietMakerCreate';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import {
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	withStyles,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import authProvider from './providers/authProvider';
import IngredientCategoryList from './components/categories/IngredientCategoryList';
import IngredientCategoryCreate from './components/categories/IngredientCategoryCreate';
import IngredientCategoryEdit from './components/categories/IngredientCategoryEdit';
import DishCategoryList from './components/categories/DishCategoryList';
import DishCategoryCreate from './components/categories/DishCategoryCreate';
import DishCategoryEdit from './components/categories/DishCategoryEdit';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import polishMessages from './translation/polishMessages';
import ClientCreate from './components/Clients/ClientCreate';
import ClientEdit from './components/Clients/ClientEdit';
import ClientList from './components/Clients/ClientList';
import IngredientDishCreate from './components/IngredientDish/IngredientDishCreate';
import CustomCloneButton from './components/Meals/CustomCloneLayout';
import IngredientDishEdit from './components/IngredientDish/IngredientDishEdit';
import Permissions from './components/Permissions';
import FlashList from './components/flash/FlashList';
import FlashListEdit from './components/flash/FlashListEdit';
import FlashListCreate from './components/flash/FlashListCreate';
import TabCategoryList from './components/TabCategory/TabCateogryList';
import TabCategoryCreate from './components/TabCategory/TabCategoryCreate';
import TabCategoryEdit from './components/TabCategory/TabCategoryEdit';
import RecipeBookList from './components/RecipeBookMeals/RecipeBookList';
import RecipeBookCreate from './components/RecipeBookMeals/RecipeBookCreate';
import RecipeBookEdit from './components/RecipeBookMeals/RecipeBookEdit';
import GenderList from './components/Genders/GenderList';
import GenderCreate from './components/Genders/GenderCreate';
import AmountDishes from './components/RecipeBookAmountDishesInDiets/AmountDishes';
import AmountCreate from './components/RecipeBookAmountDishesInDiets/AmountCreate';
import RecipeBooksCalorifics from './components/RecipeBooksCalorifics/RecipeBooksCalorifics';
import RecipeBookCalorificsCreate from './components/RecipeBooksCalorifics/RecipeBookCalorificsCreate';
import RecipeBookLabelsList from './components/RecibeBookLables/RecipeBookLabelsList';
import RecipeBookLablesCreate from './components/RecibeBookLables/RecipeBookLablesCreate';
import GenderEdit from './components/Genders/GenderEdit';
import RecipeBookLablesEdit from './components/RecibeBookLables/RecipeBookLablesEdit';
import AmountEdit from './components/RecipeBookAmountDishesInDiets/AmountEdit';
import RecipeBookCalorificsEdit from './components/RecipeBooksCalorifics/RecipeBookCalorificsEdit';
import RecipeBooksCalorificsDish from './components/RecipeBooksCalorificsDish/RecipeBooksCalorificsDish';
import RecipeBookCalorificsDishCreate from './components/RecipeBooksCalorificsDish/RecipeBookCalorificsDishCreate';
import RecipeBookCalorificsDishEdit from './components/RecipeBooksCalorificsDish/RecipeBookCalorificsDishEdit';
import RecipeBookDependences from './components/RecipeBookDependences/RecipeBookDependences';
import RecipeBookDependencesCreate from './components/RecipeBookDependences/RecipeBookDependencesCreate';
import RecipeBookDependencesEdit from './components/RecipeBookDependences/RecipeBookDependencesEdit';

const messages = {
	pl: polishMessages,
};

const i18nProvider = polyglotI18nProvider(() => polishMessages, 'pl');

const useStyles = makeStyles({
	avatar: {
		height: 30,
		width: 30,
	},
});

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			main: '#f1562f',
			background: '#ffa84c',
		},
		secondary: {
			main: '#ffa84c',
		},
	},
	navbar: {
		primary: '#ffa84c',
	},
	header: {
		backgroundColor: '#ff0000',
	},
	sidebar: {
		width: 300,
		backgroundColor: '#000000',
		drawerPaper: {
			backgroundColor: '#000000',
		},
	},
});

const MyCustomIcon = () => {
	const classes = useStyles();
	return (
		<Avatar
			className={classes.avatar}
			src="https://marmelab.com/images/avatars/adrien.jpg"
		/>
	);
};

const useSidebarStyles = makeStyles({
	drawerPaper: {
		backgroundColor: '#2F3438',
		color: '#ffffff',
	},
});

const MySidebar = (props) => {
	// const classes = useSidebarStyles();
	return <Sidebar {...props} />;
};

const MyUserMenu = (props) => {
	return <UserMenu {...props} icon={<MyCustomIcon />} />;
};

const useMenuStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
	group: {
		fontWeight: 'bold',
	},
	active: {
		fontWeight: 'bold',
		color: '#f4a84c',
	},
}));

function NestedList({ value, open, setOpen, keyVal }) {
	const classes = useMenuStyles();
	const Icon = 'icon' in value[0].options ? value[0].options.icon : () => <></>;
	const location = useLocation();

	const groupedRes = groupBy(
		value.filter((r) => r.options.label),
		'options.subGroup'
	);
	const [openSub, setOpenSub] = useState(false);

	return (
		<>
			<ListItem
				className={classes.group}
				button
				onClick={() => setOpen(open === keyVal ? null : keyVal)}
			>
				<ListItemIcon>
					<Icon />
				</ListItemIcon>
				<ListItemText
					classes={{
						primary: classes.group,
					}}
					primary={keyVal}
				/>
				{open === keyVal ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open === keyVal} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{Object.entries(groupedRes).map(([key, value]) => {
						if (key == 'undefined') {
							return value.map((item, i) => (
								<ListItem
									component={NavLink}
									to={'/' + item.name}
									className={classes.nested}
									key={i}
									button
								>
									<ListItemIcon>
										<item.icon />
									</ListItemIcon>
									<ListItemText
										classes={{
											primary: location.pathname.includes(item.name)
												? classes.active
												: '',
										}}
										primary={item.options.label}
									/>
								</ListItem>
							));
						} else {
							return (
								<>
									<ListItem
										className={classes.nested}
										button
										onClick={() => setOpenSub(openSub === key ? null : key)}
									>
										<ListItemIcon>
											<Icon />
										</ListItemIcon>
										<ListItemText
											classes={{
												primary: classes.group,
											}}
											primary={key}
										/>
										{openSub === key ? <ExpandLess /> : <ExpandMore />}
									</ListItem>
									<Collapse in={openSub === key} timeout="auto" unmountOnExit>
										<List component="div" disablePadding>
											{value.map((item, i) => (
												<>
													{' '}
													<ListItem
														component={NavLink}
														to={'/' + item.name}
														className={classes.nested}
														key={i}
														button
													>
														<ListItemIcon>
															<item.icon />
														</ListItemIcon>
														<ListItemText
															classes={{
																primary: location.pathname.includes(item.name)
																	? classes.active
																	: '',
															}}
															primary={item.options.label}
														/>
													</ListItem>
												</>
											))}
										</List>
									</Collapse>
								</>
							);
						}
					})}
				</List>
			</Collapse>
		</>
	);
}

const MyMenu = (props) => {
	const resources = useSelector(getResources);
	const classes = useMenuStyles();
	const groupedRes = groupBy(
		resources.filter((r) => r.options.label),
		'options.group'
	);
	const location = useLocation();

	const [open, setOpen] = useState(null);

	return (
		<div>
			<List component="nav" className={classes.root}>
				{Object.entries(groupedRes).map(([key, val]) => {
					if (key === 'undefined') {
						const arr = val.map((item, i) => {
							return (
								<ListItem
									key={i}
									button
									component={NavLink}
									to={'/' + item.name}
								>
									<ListItemIcon>
										<item.icon />
									</ListItemIcon>
									<ListItemText
										classes={{
											primary: location.pathname.includes(item.name)
												? classes.active + ' ' + classes.group
												: classes.group,
										}}
										primary={item.options.label}
									/>
								</ListItem>
							);
						});

						arr.unshift(
							<>
								<ListItem
									className={classes.group}
									button
									key={5}
									onClick={() => setOpen(open === 5 ? null : 5)}
								>
									<ListItemIcon>
										<ViewListIcon />
									</ListItemIcon>
									<ListItemText
										classes={{
											primary: classes.group,
										}}
										primary={'Przepiśnik'}
									/>
									{open === 5 ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={open === 5} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										<ListItem
											component={NavLink}
											to={'/ingredients'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<AppleIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes('ingredients')
														? classes.active
														: '',
												}}
												primary={'Produkty'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_dishes'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<RestaurantMenuIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'ingredient_categories'
													)
														? classes.active
														: '',
												}}
												primary={'Tworzenie potraw'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_dependences'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<RestaurantMenuIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_dependences'
													)
														? classes.active
														: '',
												}}
												primary={'Tabela kaloryczności'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_calorific_dishes'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<KitchenIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_calorific_dishes'
													)
														? classes.active
														: '',
												}}
												primary={'Ilość kalorii w posiłku'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_genders'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<WcIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_genders'
													)
														? classes.active
														: '',
												}}
												primary={'Płeć'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_amount_dishes_in_diets'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<ExposurePlus1Icon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_amount_dishes_in_diets'
													)
														? classes.active
														: '',
												}}
												primary={'Ilość posiłków w dietach'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_calorifics'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<KitchenIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_calorifics'
													)
														? classes.active
														: '',
												}}
												primary={'Ilość kalorii w diecie'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/recipe_book_sub_categories'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<LabelIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'recipe_book_sub_categories'
													)
														? classes.active
														: '',
												}}
												primary={'Etykiety'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/dish_categories'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<CategoryIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes('dish_categories')
														? classes.active
														: '',
												}}
												primary={'Kategorie potraw'}
											/>
										</ListItem>
										<ListItem
											component={NavLink}
											to={'/ingredient_categories'}
											className={classes.nested}
											key={1}
											button
										>
											<ListItemIcon>
												<CategoryIcon />
											</ListItemIcon>
											<ListItemText
												classes={{
													primary: location.pathname.includes(
														'ingredient_categories'
													)
														? classes.active
														: '',
												}}
												primary={'Kategorie zamienników'}
											/>
										</ListItem>
									</List>
								</Collapse>
							</>
						);

						return arr;
					} else {
						return (
							<NestedList
								open={open}
								setOpen={setOpen}
								key={key}
								value={val}
								keyVal={key}
								resources={resources}
								groupedRes={groupedRes}
							/>
						);
					}
				})}
			</List>
		</div>
	);
};

const styles = {
	title: {
		flex: 1,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	spacer: {
		flex: 1,
	},
};

const CustomAppBar = withStyles(styles)(({ classes, userMenu, ...props }) => {
	const authProvider = useAuthProvider();
	return (
		<AppBar userMenu={userMenu || !!authProvider} {...props}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>
			<span className={classes.spacer} />
		</AppBar>
	);
});

const MyLayout = (props) => (
	<Layout {...props} appBar={CustomAppBar} menu={MyMenu} />
);

const getHeaders = () =>
	localStorage.getItem('token')
		? {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
		  }
		: {};

export const fetchHydra = (url, options = {}) =>
	baseFetchHydra(url, {
		...options,
		headers: getHeaders,
	});

const RedirectToLogin = () => {
	const introspect = useIntrospection();

	if (localStorage.getItem('token')) {
		introspect();
		return <></>;
	}
	return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
	try {
		const { api } = await parseHydraDocumentation(entrypoint, {
			headers: getHeaders,
		});
		return { api };
	} catch (result) {
		if (result.status === 401) {
			// Prevent infinite loop if the token is expired
			localStorage.removeItem('token');

			return {
				api: result.api,
				customRoutes: [<Route path="/" component={RedirectToLogin} />],
			};
		}

		throw result;
	}
};

export const dataProviderHydra = baseHydraDataProvider(
	process.env.REACT_APP_API_URL + '/api',
	fetchHydra,
	apiDocumentationParser
);

function App() {
	const [loaded, setLoaded] = useState(false);

	authProvider
		.checkAuth()
		.then(() =>
			setTimeout(
				() => dataProviderHydra.introspect().then(() => setLoaded(true)),
				200
			)
		)
		.catch(() => {
			setLoaded(true);
		});

	return loaded ? (
		<Admin
			i18nProvider={i18nProvider}
			locale="pl"
			messages={messages}
			customRoutes={[
				<Route path="/products" component={PriceList} />,
				<RouteWithoutLayout
					noLayout={true}
					path="/permissions"
					component={Permissions}
				/>,
				<RouteWithoutLayout
					noLayout={true}
					path="/oko"
					component={CustomCloneButton}
				/>,
			]}
			loading={loaded}
			theme={theme}
			authProvider={authProvider}
			layout={MyLayout}
			dataProvider={dataProviderHydra}
		>
			<Resource
				options={{
					label: 'Administratorzy',
					group: 'Administratorzy',
					icon: UserIcon,
				}}
				name="users"
				list={UserList}
				create={UserCreate}
				edit={UsersEdit}
				icon={UserIcon}
			/>
			<Resource
				options={{ label: 'Grupy administratorów', group: 'Administratorzy' }}
				name="groups"
				list={UserGroup}
				create={UserGroupCreate}
				edit={UserGroupEdit}
				icon={UsersIcon}
			/>
			<Resource
				options={{ label: 'Produkty', group: 'E-booki', icon: FastfoodIcon }}
				name="ingredients"
				list={ProductList}
				create={ProductCreate}
				edit={ProductEdit}
				icon={AppleIcon}
			/>
			<Resource
				options={{ label: 'Tworzenie e-booków', group: 'E-booki' }}
				name="diets"
				list={DietMaker}
				create={DietMakerCreate}
				edit={DietMakerEdit}
				icon={AddIcon}
			/>
			<Resource
				options={{ label: 'FAQ' }}
				name="faqs"
				list={FaqList}
				create={FaqCreate}
				edit={FaqEdit}
				icon={ViewListIcon}
			/>
			<Resource
				options={{ label: 'Potrawy', group: 'E-booki', icon: FastfoodIcon }}
				name="dishes"
				list={MealList}
				create={MealCreate}
				edit={MealEdit}
				icon={FastfoodIcon}
			/>
			<Resource
				options={{
					label: 'Kategoria potraw',
					group: 'E-booki',
					icon: FastfoodIcon,
				}}
				name="dish_categories"
				list={DishCategoryList}
				create={DishCategoryCreate}
				edit={DishCategoryEdit}
				icon={FastfoodIcon}
			/>
			<Resource
				options={{ label: 'Wiadomość Flash' }}
				name="news"
				list={FlashList}
				edit={FlashListEdit}
				create={FlashListCreate}
				icon={ViewListIcon}
			/>
			<Resource
				options={{ label: 'Klienci' }}
				name="clients"
				list={ClientList}
				edit={ClientEdit}
				create={ClientCreate}
				icon={ViewListIcon}
			/>
			<Resource
				name="ingredient_dishes"
				create={IngredientDishCreate}
				edit={IngredientDishEdit}
			/>
			<Resource name="diet_dish_lunches" />
			<Resource name="diet_dish_second_lunches" />
			<Resource name="diet_dish_second_breakfasts" />
			<Resource name="diet_dish_breakfasts" />
			<Resource name="diet_dish_dinners" />
			<Resource name="diet_dish_desserts" />
			<Resource name="ingredient_units" />
			<Resource name="units" />
			<Resource name="diet_templates" />
			<Resource
				name="recipe_book_genders"
				list={GenderList}
				create={GenderCreate}
				edit={GenderEdit}
			/>
			<Resource
				name="recipe_book_sub_categories"
				list={RecipeBookLabelsList}
				create={RecipeBookLablesCreate}
				edit={RecipeBookLablesEdit}
			/>
			<Resource
				name="recipe_book_amount_dishes_in_diets"
				list={AmountDishes}
				create={AmountCreate}
				edit={AmountEdit}
			/>
			<Resource
				name="recipe_book_calorifics"
				list={RecipeBooksCalorifics}
				create={RecipeBookCalorificsCreate}
				edit={RecipeBookCalorificsEdit}
			/>
			<Resource
				name="recipe_book_calorific_dishes"
				list={RecipeBooksCalorificsDish}
				create={RecipeBookCalorificsDishCreate}
				edit={RecipeBookCalorificsDishEdit}
			/>
			<Resource
				name="recipe_book_dishes"
				list={RecipeBookList}
				label={'Produkty'}
				create={RecipeBookCreate}
				edit={RecipeBookEdit}
			/>
			<Resource
				name="recipe_book_dependences"
				list={RecipeBookDependences}
				label={'Produkty'}
				create={RecipeBookDependencesCreate}
				edit={RecipeBookDependencesEdit}
			/>
			<Resource name="diet_dish_snacks" />
			<Resource name="attachments" />
			<Resource
				options={{
					label: 'Kategoria zamienników',
					group: 'E-booki',
					icon: FastfoodIcon,
				}}
				name="ingredient_categories"
				list={IngredientCategoryList}
				create={IngredientCategoryCreate}
				edit={IngredientCategoryEdit}
				icon={CategoryIcon}
			/>
			<Resource
				options={{
					permission: 'isDishesAccess',
					label: 'Kategorie zakładek',
					group: 'E-booki',
					icon: FastfoodIcon,
				}}
				name="tab_categories"
				list={TabCategoryList}
				create={TabCategoryCreate}
				edit={TabCategoryEdit}
				icon={CategoryIcon}
			/>
		</Admin>
	) : (
		<></>
	);
}

export default App;
