import {
    BooleanField,
    Create,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import React from "react";
import PasswordCustomInput from "../Fields/PasswordCustomInput";


const RecipeBookCalorificsDishCreate = (props) => {
    return <Create title='Płeć' {...props}>
        <SimpleForm redirect={"/recipe_book_calorific_dishes"}>
            <NumberInput source='title' label="Ilość kalorii w posiłku"/>
        </SimpleForm>
    </Create>
}
export  default RecipeBookCalorificsDishCreate;