import React, {useEffect, useState, cloneElement, useMemo, useCallback} from 'react';
import ButtonRemove from '@material-ui/core/Button';
import {
    ArrayInput,
    AutocompleteInput,
    Button,
    Create,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
    Toolbar,
    useCreateController,
    useDataProvider,
    useGetList,
    CreateContextProvider, useRecordContext, useNotify, minValue, required, minLength, maxLength
} from 'react-admin';
import {CustomImageField} from "../Fields/CustomArrayImageField";
import {Save} from "@material-ui/icons";
import {useFormState} from "react-final-form";
import {useHistory} from "react-router";
import {spanStyle} from "../DietMaker/DietMakerCreate";
import RichTextInput from "ra-input-rich-text";
import {pick} from "lodash/object";
import {isEmpty} from "lodash/lang";
import {CircularProgress} from "@material-ui/core";
import {object} from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
import {CardUl} from "./MealEdit";
import styled from "styled-components";
import {useTranslate} from "ra-core";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";


export const GoBackToolbar = (props) => {
    const history = useHistory();
    const {values, ...rest} = useFormState();
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const onSuccess = () => {
        history.goBack();
    };

    const tasks = []
    const {data, ids, loading, error} = useGetList(
        'dishes',
        {page: 1, perPage: 100},
    );
    const [isCodeInBase, setIsCodeInBase] = useState(false)


    useEffect(() => {
        Object.values(data).forEach(e => {

            if (e.dishCode === values.dishCode) {
                setIsCodeInBase(true)

            }
            return isCodeInBase;
        })
    }, [])

    const save = () => {

        delete values['orginId']
        delete values['@id']

        values?.ingredientsForDishes?.forEach((ingr, index) => {
            tasks.push(dataProvider.create('ingredient_dishes', {
                data: {
                    dish: null,
                    grams: ingr.grams,
                    ingredientDishOrder: index + 1,
                    ingredient: ingr.ingredient,
                    portionOfSauce: ingr.portionOfSauce,
                }
            }))

        })

        Promise.all(tasks).then(ingr => {
            try {
                dataProvider.create('dishes', {
                    data: {
                        ...values,
                        ingredientsForDishes: ingr.map(s => s.data.id)
                    }
                }).then((r) => {
                    onSuccess()
                }).catch((err) => {
                    if (values.recipe === undefined) {
                        notify('Pole "Przepis na potrawę" jest wymagane', 'warning')
                    } else {
                        notify('Kod potrawy już istanieje', 'warning')
                    }
                })
            } catch (e) {
                console.log(e)
                notify('Ten kod potrawy już istnieje!', 'warning')
            }

        })

    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}
                    disabled={values.title === undefined  || values.dishCode === undefined}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};


export const validateGrams = [required()]

const CustomCreate = props => {
    const [ingredients, setIngredients] = useState(null)
    const dataProvider = useDataProvider();

    const createControllerProps = useCreateController(props);
    const {
        basePath, // deduced from the location, useful for action buttons
        defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
        record, // empty object, unless some values were passed in the location state to prefill the form
        redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the create callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to create the record
        version, // integer used by the refresh feature
    } = createControllerProps;

    useEffect(async () => {
        if (record.ingredientsForDishes) {
            let arr = []
            for (const ingredient of record.ingredientsForDishes) {
                const record = await dataProvider.getOne('ingredient_dishes', {
                    id: ingredient
                })
                if (record.data) {
                    arr.push(pick(record.data, ['grams', 'ingredient', 'dish', 'portionOfSauce', 'ingredientDishOrder']));
                }
            }

            setIngredients(arr)
        }
    }, [record.ingredientsForDishes])

    if (!record) return <CircularProgress/>

    const newRecord = {
        ...createControllerProps.record,
        ingredientsForDishes: ingredients?.sort((a, b) => a.ingredientDishOrder - b.ingredientDishOrder),
        id: null,
    }

    return <CreateContextProvider value={{
        ...createControllerProps,
        record: newRecord
    }}>
        {cloneElement(props.children, {
            basePath,
            record: newRecord,
            redirect,
            resource,
            save,
            saving,
            version,
        })}
    </CreateContextProvider>
}

const validateUserCreation = (values) => {
    console.log(values)
};

const MealCreate = (props) => {
    const dataProvider = useDataProvider();
    const [openNutrition, setOpenNutrition] = useState(false)
    const {data, ids, total, loading, loaded, error, refetch} = useGetList(
        'ingredients',
        {page: 1, perPage: 500}
    );


    const required = (message = 'Wymagane') =>
        value => value ? undefined : message;

    const validate = [required()]

    // const validateUserCreation = (values) => {
    //     const errors = [];
    //     Object.values(xxx.data).map((e)=> {
    //         if (e.title === values.title) {
    //             errors.push("Nazwa jest już w bazie")
    //             console.log("kurczas")
    //         }
    //     })
    //     return errors;

    // if (!values.firstName) {
    //     errors.firstName = 'The firstName is required';
    // }
    // if (!values.age) {
    //     // You can return translation keys
    //     errors.age = 'ra.validation.required';
    // } else if (values.age < 18) {
    //     // Or an object if the translation messages need parameters
    //     errors.age = {
    //         message: 'ra.validation.minValue',
    //         args: { min: 18 }
    //     };
    // }

    // };

    const choices = (el) => {

        return Object.values(el).map((e) => ({
            id: e.id,
            name: e.title
        }))
    }

    const validateValue = [minValue(0, "Wartość musi być dodatnia"),];
    const [selectedIngredient, setSelectedIngredient] = useState(null)
    const [ingredientArray, setIngredientsArray] = useState([])
    const [calculatedNutritional, setCalculatedNutritional] = useState({});
    const [newArr, setNewArr] = useState([])
    const [ingredientIndex, setIngredientIndex] = useState(null)

    const validateRecipe = [required(), minLength(2), maxLength(15)];

    useEffect(() => {

        if (selectedIngredient !== null) {
            setIngredientsArray(prev => {
                let isInArray = false
                const arr = [...prev]
                arr.forEach((value, index) => {
                    if (index === ingredientIndex) {
                        arr[index] = selectedIngredient;
                        isInArray = true
                    }
                })
                if (!isInArray) {
                    arr.push({...selectedIngredient, grams: 0});
                }
                return [...arr];
            })
        }

    }, [selectedIngredient])

    const DefaultRemoveButton = props => {

        const translate = useTranslate();

        return (
            <ButtonRemove size="small"  {...props} onClick={(e) => {
                ingredientArray.splice(props.className.replace(/^[^0-9]+/, ''), 1)
                const newIngredientArray = [...ingredientArray]
                setNewArr(newIngredientArray)
                props.onClick(e)
                if (ingredientArray.length === 0) {
                    setCalculatedNutritional(prev => {
                        return {...prev,
                            calculatedDishCalorific: 0,
                            calculatedDishCarbohydrates: 0,
                            calculatedDishProteins: 0,
                            calculatedDishFats: 0,
                            calculatedDishPrice: 0}
                    })
                    setOpenNutrition(false)
                }
            }}>
                <CloseIcon/>
                {translate('ra.action.remove')}
            </ButtonRemove>
        );
    };

    useEffect(() => {
        if (newArr.length > 0) {
            setIngredientsArray(newArr)
        }
    }, [newArr])

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `/api/dishes/calculate_info`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({ingredientsForDishes: ingredientArray})

        }).then(response => {
            return response.json()
        }).then(data => {
            setCalculatedNutritional(data)
        })
    }, [ingredientArray])
    return (
        <CustomCreate title='Stwórz posiłek'  {...props} >
            <SimpleForm redirect={'/dishes'} toolbar={<GoBackToolbar/>}>
                <TextInput multiline source="title" label="Nazwa potrawy" required validate={validate}/>
                <TextInput source="dishCode" label="Kod potrawy" validate={validate} required/>
                <TextInput source="videoUrl" label="Film z przygotowaniem"/>
                <ReferenceInput label={"Zdjęcia potrawy"} source="photo" reference="attachments">
                    <CustomImageField name={'photo'}/>
                </ReferenceInput>

                <ReferenceArrayInput label="Kategoria" source="categories" reference="dish_categories">
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>

                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <SelectArrayInput optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                <RichTextInput multiline source="recipe" label="Przepis na potrawę*" required/>

                <span style={spanStyle}>Dodaj produkt</span>

                <ArrayInput source="ingredientsForDishes" label={""}>
                    <SimpleFormIterator removeButton={<DefaultRemoveButton/>}>
                        <AutocompleteInput label={"Produkty"} source="ingredient" choices={choices(data)}
                                           onFocus={(e) => {
                                               setOpenNutrition(true)
                                               setIngredientIndex(parseInt(e.target.name.split(".")[0].replace(/^[^0-9]+/, ''), 10))
                                           }}
                                           onChange={(e) => {
                                               setSelectedIngredient(prev => {
                                                   return {...prev, ingredient: e}
                                               })
                                           }}/>
                        <TextInput source={"dish"} defaultValue={null} label={""} type={"hidden"}/>
                        <NumberInput validate={validateGrams} defaultValue={0} required={true} source={"grams"}
                                     label={"Ilość gram"}
                                     onChange={e => {
                                         setSelectedIngredient(prev => {
                                             return {...prev, grams: +e.target.value}
                                         })
                                     }}/>
                        <NumberInput source={"portionOfSauce"} label={"Porcja"} defaultValue={0}
                                     validate={validateValue} onChange={e => {
                            setSelectedIngredient(prev => {
                                return {...prev, portionOfSauce: +e.target.value}
                            })
                        }}/>
                    </SimpleFormIterator>
                </ArrayInput>
                {openNutrition && <CardUl>
                    <li>
                        <div>Tłuszcze</div>
                        <div>{calculatedNutritional?.calculatedDishFats} g</div>
                    </li>
                    <li>
                        <div>Węglowodany</div>
                        <div>{calculatedNutritional?.calculatedDishCarbohydrates} g</div>
                    </li>
                    <li>
                        <div>Białko</div>
                        <div>{calculatedNutritional?.calculatedDishProteins} g</div>
                    </li>
                    <li>
                        <div>Ilość kalorii</div>
                        <div>{calculatedNutritional?.calculatedDishCalorific} Kcal</div>
                    </li>
                    <button onClick={() => {
                        setOpenNutrition(false)
                    }
                    }><CancelIcon/></button>
                </CardUl>}


                {/*<ProductCreateButton/>*/}
                {/*<ReferenceArrayField*/}
                {/*    label="Produkty"*/}
                {/*    reference="ingredients"*/}
                {/*    source="ingredientsForDishes"*/}
                {/*>*/}
                {/*    <Datagrid>*/}
                {/*        <IngredientNames/>*/}
                {/*        <DeleteButton undoable={false} redirect={false}/>*/}
                {/*    </Datagrid>*/}
                {/*</ReferenceArrayField>*/}

                {/*<ReferenceArrayInput label="Wybór produktów" source="ingredientsForDishes" reference="ingredients">*/}
                {/*    <FilteredSelectField  optionText="title" />*/}
                {/*</ReferenceArrayInput>*/}

                <NumberInput source='approximatePrepareTime' label="Czas przygotowywania"/>
            </SimpleForm>

        </CustomCreate>

    )
}

export default MealCreate;
