import React from "react";
import {Edit, SimpleForm, TextInput, NumberInput, DateInput, NumberField, SelectInput, BooleanInput} from 'react-admin';


const UserGroupEdit = (props) => {
    return (
        <Edit title='Edycja użytkownika'  {...props}>
            <SimpleForm redirect={'/groups'}>
                <TextInput source={'title'} label='Nazwa grupy'/>
                <BooleanInput label="Użytkownicy" source="isUsersAccess" />
                <BooleanInput label="Grupy użytkowników" source="isGroupsAccess" />
                {/*<BooleanInput label="Klienci" source="isClientsAccess" />*/}
                <BooleanInput label="Dania" source="isDishesAccess" />
                <BooleanInput label="FAQ" source="isFAQAccess" />
            </SimpleForm>

        </Edit>
    )
}

export default UserGroupEdit;